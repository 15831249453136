// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boxing-promo-js": () => import("/opt/build/repo/src/pages/boxing-promo.js" /* webpackChunkName: "component---src-pages-boxing-promo-js" */),
  "component---src-pages-howard-university-js": () => import("/opt/build/repo/src/pages/howard-university.js" /* webpackChunkName: "component---src-pages-howard-university-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitation-js": () => import("/opt/build/repo/src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-mmt-com-js": () => import("/opt/build/repo/src/pages/mmt-com.js" /* webpackChunkName: "component---src-pages-mmt-com-js" */),
  "component---src-pages-orders-portal-js": () => import("/opt/build/repo/src/pages/orders-portal.js" /* webpackChunkName: "component---src-pages-orders-portal-js" */),
  "component---src-pages-prepress-js": () => import("/opt/build/repo/src/pages/prepress.js" /* webpackChunkName: "component---src-pages-prepress-js" */),
  "component---src-pages-sellsheet-js": () => import("/opt/build/repo/src/pages/sellsheet.js" /* webpackChunkName: "component---src-pages-sellsheet-js" */),
  "component---src-pages-sepsis-awareness-js": () => import("/opt/build/repo/src/pages/sepsis-awareness.js" /* webpackChunkName: "component---src-pages-sepsis-awareness-js" */),
  "component---src-pages-sporting-events-js": () => import("/opt/build/repo/src/pages/sporting-events.js" /* webpackChunkName: "component---src-pages-sporting-events-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-visen-brochure-js": () => import("/opt/build/repo/src/pages/visen-brochure.js" /* webpackChunkName: "component---src-pages-visen-brochure-js" */),
  "component---src-pages-vista-water-js": () => import("/opt/build/repo/src/pages/vista-water.js" /* webpackChunkName: "component---src-pages-vista-water-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

